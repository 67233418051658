.main-container {
	text-align: center;
	width: 100%;
	height: 100%;
}

#action-fab {
	position: fixed;
	right: 60px;
	bottom: 70px;
}
#pdf-fab {
	position: fixed;
	right: 130px;
	bottom: 70px;
}
