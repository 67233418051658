.green {
	background-color: green;
	color: white !important;
}

.transparent {
	background-color: transparent;
}

.orange {
	background-color: darkorange;
	color: white !important;
}

.red {
	background-color: red;
	color: white !important;
}