@import url(https://fonts.googleapis.com/css2?family=Roboto+Condensed&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Red+Hat+Display:ital,wght@0,300..900;1,300..900&display=swap);
html,body{position:relative}.standardBackgroundClass{background-image:url(/static/media/background.6449c287.jpg);background-size:cover}.lawalBackgroundClass{background-image:url(/static/media/background-lawal.7869545e.jpg);background-size:cover}.mgiBackgroundClass{background-image:url(/static/media/background-mgi.30e013c5.jpg);background-size:cover}
.menuBar {
	position: absolute;
	top: 65px;
	right: 0px;
	padding: 10px;
}

.headerCard {
	height: 100%;
}
.loginContainer {
	padding: 70px
}

.login-container {
	box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.38);
	-webkit-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.38);
	-moz-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.38);
}

.home-cta-container {
	padding: 70px;
}
.main-container {
	text-align: center;
}

.info-container {
	background-color: gainsboro;
	box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.38);
	-webkit-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.38);
	-moz-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.38);
}
.form-container {
	box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.38);
	-webkit-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.38);
	-moz-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.38);
}

.left-align {
	text-align: left;
}
.main-container {
	text-align: center;
	width: 100%;
	height: 100%;
}

#action-fab {
	position: fixed;
	right: 60px;
	bottom: 70px;
}
#pdf-fab {
	position: fixed;
	right: 130px;
	bottom: 70px;
}

.finalize-data-container {
	box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.38);
	-webkit-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.38);
	-moz-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.38);
}

.text-left {
	text-align: left !important;
}
.green {
	background-color: green;
	color: white !important;
}

.transparent {
	background-color: transparent;
}

.orange {
	background-color: darkorange;
	color: white !important;
}

.red {
	background-color: red;
	color: white !important;
}
.summary-container {
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
}

.text-center {
	text-align: center;
}
.onboard-container {
	background-color: #FAFBFB;
	box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.38);
	-webkit-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.38);
	-moz-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.38);
}

