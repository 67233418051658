html, body {
	position: relative;
}

.standardBackgroundClass {
	background-image: url("background.jpg");
	background-size: cover;
}

.lawalBackgroundClass {
	background-image: url("background-lawal.jpg");
	background-size: cover;
}

.mgiBackgroundClass {
	background-image: url("background-mgi.jpg");
	background-size: cover;
}

@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display:ital,wght@0,300..900;1,300..900&display=swap');